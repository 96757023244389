import { flatten } from "lottie-colorify";
import lottie from "lottie-web";
import React, { useEffect, useRef } from "react";

import config from "../../../config";
import resetButtonAnimation from "../../assets/gif/resetButton.json";
import colours from "../../styles/colours";
import styles from "./Spinner.styles";
// web spinner loader
interface SpinnerProps {
  containerStyle?: React.CSSProperties;
  color?: string;
  autoPlay?: boolean;
}

const Spinner: React.FC<SpinnerProps> = ({
  containerStyle = {},
  color,
  autoPlay = true,
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    lottie.destroy();
    lottie.loadAnimation({
      container: wrapperRef.current,
      renderer: "canvas",
      loop: true,
      autoplay: autoPlay ? autoPlay : !config.visualTestingMode,
      animationData: flatten(color ?? colours.yellow, resetButtonAnimation),
    });
    if (autoPlay == false) {
      lottie?.pause();
    }
  }, [color, autoPlay]);

  return (
    <div
      style={{ ...styles.webLoader, ...containerStyle }}
      ref={wrapperRef}
      data-testid='spinner-loader'
      id='spinner-loader'
    />
  );
};

export default Spinner;
