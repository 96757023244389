import moment, { Moment } from "moment-timezone";

import { locales } from "../../locales/locales.enum";
import { MenuSection, TimePeriod } from "../../redux_store/menu/models";
import {
  Store,
  StoreMenu,
  StoreOpenTime,
  StoreSurcharges,
  StoreTag,
} from "../../redux_store/store/models";
import { OrderResponse } from "../../services/api/order/model";
import { StoreDays, StoreTradingHour } from "../../services/api/store/model";
import {
  checkSelectedTimeWithinTradingHours,
  findTimePeriodsForDay,
  getClosingTimeMenuHoursForDay,
  getOpeningTimeMenuHoursForDay,
} from "../Menu/utils";
import { StoreTagLabel } from "./models";

export type Coordinate = [number, number];

interface CoordsDetails {
  center: Coordinate;
  boundingBox?: {
    SW: Coordinate;
    NE: Coordinate;
  };
  latDelta: number;
  longDelta: number;
  zoom: number;
}

export interface MapCoordinates {
  lat: number;
  long: number;
  latDelta: number;
  longDelta: number;
  zoom?: number;
}

export enum StoreSearchResult {
  REGION = "REGION",
  STORE = "STORE",
}

export const ORDER_TIME_TIMER_DELAY = 30000;
// default to 50 km, used for determine when to show the no restaurant nearby message
export const DISTANCETHRESHOLD_AU = 50000;
// default to 50 mi, used for determine when to show the no restaurant nearby message
export const DISTANCETHRESHOLD_US = 50;

export const DEFAULT_USER_DELTA = 0.05;
export const DEFAULT_USER_ZOOM = 15;
export const DEFAULT_REGION_DELTA = 1;
export const DEFAULT_REGION_ZOOM = 10;

export const auDefault: CoordsDetails = {
  center: [-24.15, 133.25],
  boundingBox: {
    SW: [-43.470883, 111.2049213],
    NE: [-9.776762, 153.5682003],
  },
  latDelta: 45,
  longDelta: 45,
  zoom: 4.5,
};

export const usDefault: CoordsDetails = {
  center: [35.8, -95.55],
  boundingBox: {
    SW: [19.758308, -128.07766],
    NE: [50.171184, -63.258328],
  },
  latDelta: 60,
  longDelta: 60,
  zoom: 4,
};

/**
 * Sets coordinates of the map depend on the user's location or the country.
 */
export const getInitialCoordinates = (
  country: locales,
  isLocationOn: boolean,
  latLong?: Coordinate,
  searchResultAplly?: StoreSearchResult
): MapCoordinates => {
  const countryCoordinates = country === locales.AU ? auDefault : usDefault;

  let newCoords: MapCoordinates;

  if (isLocationOn && latLong && !searchResultAplly) {
    newCoords = {
      lat: latLong[0],
      long: latLong[1],
      latDelta: DEFAULT_USER_DELTA,
      longDelta: DEFAULT_USER_DELTA,
      zoom: DEFAULT_USER_ZOOM,
    };
  } else if (searchResultAplly === StoreSearchResult.REGION && latLong) {
    newCoords = {
      lat: latLong[0],
      long: latLong[1],
      latDelta: DEFAULT_REGION_DELTA,
      longDelta: DEFAULT_REGION_DELTA,
      zoom: DEFAULT_REGION_ZOOM,
    };
  } else if (searchResultAplly === StoreSearchResult.STORE && latLong) {
    newCoords = {
      lat: latLong[0],
      long: latLong[1],
      latDelta: DEFAULT_USER_DELTA,
      longDelta: DEFAULT_USER_DELTA,
      zoom: DEFAULT_USER_ZOOM,
    };
  } else {
    newCoords = {
      lat: countryCoordinates.center[0],
      long: countryCoordinates.center[1],
      latDelta: countryCoordinates.latDelta,
      longDelta: countryCoordinates.longDelta,
      zoom: countryCoordinates.zoom,
    };
  }

  return newCoords;
};

export const updateCenter = (
  country: locales,
  isLocationOn: boolean,
  coordsFromSearch: Coordinate,
  storeLatLong?: [string, string]
): MapCoordinates => {
  let coords: [number, number];
  if (storeLatLong) {
    coords = [parseFloat(storeLatLong[0]), parseFloat(storeLatLong[1])];
  } else {
    coords = [coordsFromSearch[0], coordsFromSearch[1]];
  }

  return getInitialCoordinates(
    country,
    isLocationOn,
    coords,
    !storeLatLong ? StoreSearchResult.REGION : StoreSearchResult.STORE
  );
};

/**
 * Formats the hour to Xpm/am.
 * @param hour
 * @returns
 */
export const hourFormatHelper = (hour: string): string => {
  if (hour) {
    const minutes = hour.split(":")[1];
    let newHour: string;
    if (minutes) {
      const fakeDate = moment().hour(parseInt(hour)).minute(parseInt(minutes));
      newHour = moment(fakeDate).format("h:mma");
    } else {
      newHour = hour;
    }
    return newHour;
  }
};

/**
 * Returns separated opening and closing hours in an array
 * for particual day if it exsists in the `TradingHour`.
 * Else will fallback to first object.
 */
export const getSelectedDayWorkingHours = (
  tradingHours: StoreTradingHour[],
  day: StoreDays
): string[] => {
  const h = tradingHours.find((tradingHour) => tradingHour.dayOfWeek === day);
  if (h?.timePeriods?.length > 0) {
    // returns hours for today
    return [
      getOpeningTimeMenuHoursForDay(h.timePeriods),
      getClosingTimeMenuHoursForDay(h.timePeriods),
    ];
  } else {
    // if label doesn't match weekday it returns hours from the first object
    return [
      getOpeningTimeMenuHoursForDay(tradingHours[0].timePeriods),
      getClosingTimeMenuHoursForDay(tradingHours[0].timePeriods),
    ];
  }
};

export const getDateWithCustomHourMinute = (time: string): Date => {
  const date = new Date();
  const hours = time.split(":")[0];
  const minutes = time.split(":")[1];

  date.setHours(Number(hours));
  date.setMinutes(Number(minutes));
  return date;
};

const findStoreTimePeriodsForDay = (
  storeTradingHour: StoreTradingHour[],
  selectedDate: Date
) => {
  let timePeriods: TimePeriod[] = [];
  const selectedDay = moment(selectedDate).format("dddd");

  const tradingHour = storeTradingHour.find(
    (hour) => hour.dayOfWeek.toLowerCase() === selectedDay.toLowerCase()
  );

  if (tradingHour) {
    timePeriods = timePeriods.concat(tradingHour.timePeriods);
  }

  return timePeriods;
};

/**
 *
 * @param storeTradingHour
 * @param selectedDate
 * @param timezone
 * @returns
 */
export const isStoreTimeValid = (
  storeTradingHour: StoreTradingHour[],
  selectedDate: Date,
  timezone: string,
  selectedDateOffsetInMinutes = 0
): boolean => {
  const selectedTime = moment(selectedDate).set("second", 0).tz(timezone);

  if (selectedDateOffsetInMinutes > 0) {
    selectedTime.add(selectedDateOffsetInMinutes, "minutes");
  }
  const selectedTimeString = selectedTime.format("HH:mm");

  const timePeriodsForDay = findStoreTimePeriodsForDay(
    storeTradingHour,
    selectedDate
  );

  if (timePeriodsForDay.length) {
    for (const timePeriod of timePeriodsForDay) {
      const openingTime = timePeriod.openTime;
      const closingTime = timePeriod.endTime;

      //return if time selected is within one of the time periods
      if (
        selectedTimeString >= openingTime &&
        selectedTimeString <= closingTime
      ) {
        return true;
      }
    }
  }

  return false;
};

const isStoreOpenSameDay = (
  selectedTime: string,
  timePeriods: TimePeriod[]
) => {
  for (let index = 0; index < timePeriods.length; index++) {
    if (selectedTime && timePeriods[index].openTime) {
      if (selectedTime < timePeriods[index].openTime) {
        return timePeriods[index].openTime;
      }
    }
  }

  return undefined;
};

export const isStoreOpen24Hours = (tradingHoursForDay: StoreTradingHour) => {
  return (
    tradingHoursForDay &&
    tradingHoursForDay.timePeriods?.length === 1 &&
    tradingHoursForDay.timePeriods[0].openTime === "00:00" &&
    tradingHoursForDay.timePeriods[0].endTime === "23:59"
  );
};

/**
 * Creates the object that consist opening status and trading copy.
 * It will return data only if trading hours exist.
 * @param tradingHours store trading hours array
 * @returns isOpen, willOpenForToday, and tradingInfoCopy
 */
export const checkStoreOpenHours = (
  tradingHours: StoreTradingHour[],
  timeZone: string,
  forDate?: Date
): StoreOpenTime => {
  let openHoursInfo: string;
  let isOpen: boolean;
  let closingSoon = false;
  let openingSoon = false;

  const nameOfDay = forDate
    ? (moment(forDate).tz(timeZone).format("dddd") as StoreDays)
    : (moment().tz(timeZone).format("dddd") as StoreDays);
  const hour = forDate
    ? moment(forDate).tz(timeZone).format("HH:mm")
    : moment().tz(timeZone).format("HH:mm");

  if (tradingHours?.length > 0) {
    const indexForDay = tradingHours.findIndex(
      (tradingHour) => tradingHour.dayOfWeek === nameOfDay
    );

    // gets opening hours array for today
    const tradingHoursForDay = getSelectedDayWorkingHours(
      tradingHours,
      nameOfDay
    );

    // gets next day from the trading hours
    // if today is the last day in the array it gets first one
    let nextOpenDay: StoreTradingHour;
    if (indexForDay > -1 && indexForDay < tradingHours.length - 1) {
      nextOpenDay = tradingHours[indexForDay];

      //check if trading hours closed for today and use next open day
      const date = nextOpenDay.date;
      if (date && nextOpenDay.timePeriods.length > 0) {
        const lastTimePeriodForDay =
          nextOpenDay.timePeriods[nextOpenDay.timePeriods.length - 1];
        const curentTime = moment(`${date} ${hour}`).tz(timeZone);
        const lastTimePeriodDate = moment(
          `${date} ${lastTimePeriodForDay.endTime}`
        ).tz(timeZone);

        //current time is more than end time for this day, select next day
        if (curentTime > lastTimePeriodDate) {
          nextOpenDay = tradingHours[indexForDay + 1];
        }
      }

      let index = indexForDay;

      let isAllChecked = false;
      while (nextOpenDay.timePeriods.length === 0 && !isAllChecked) {
        if (index === 7) {
          if (isAllChecked) {
            nextOpenDay = tradingHours[index++];
            break;
          }
          isAllChecked = true;
          index = 0;
        }
        nextOpenDay = tradingHours[index++];
      }
    } else {
      // fallback to Monday
      nextOpenDay = tradingHours[0];
    }

    // gets opening hours array for the next open day
    const nextOpenDayTradingHours = getSelectedDayWorkingHours(
      tradingHours,
      nextOpenDay.dayOfWeek
    );

    // if trading hours consists open and close time => [opens, closes]
    if (tradingHoursForDay?.length > 0 && nextOpenDayTradingHours?.length > 0) {
      const closingTimeForDay = tradingHoursForDay[1];
      const nextOpeningTime = nextOpenDayTradingHours[0];

      const isTimeValid = isStoreTimeValid(tradingHours, forDate, timeZone);
      // assigns values depend on present time beeing in the open hours range
      if (isTimeValid) {
        isOpen = true;
        const tradingHoursArray = tradingHours[indexForDay];
        if (isStoreOpen24Hours(tradingHoursArray)) {
          openHoursInfo = "Open 24 Hours";
        } else {
          openHoursInfo = `Closes ${hourFormatHelper(closingTimeForDay)}`;
          //check if store is closing soon by adding 15 mins offset
          closingSoon = !isStoreTimeValid(tradingHours, forDate, timeZone, 15);
        }
      } else {
        //user access the store, but is closed because "hour" is earlier than opening time
        const timePeriodsForDay = findStoreTimePeriodsForDay(
          tradingHours,
          forDate
        );

        const checkStoreOpenSameDay = isStoreOpenSameDay(
          hour,
          timePeriodsForDay
        );
        if (!!checkStoreOpenSameDay) {
          isOpen = false;
          openHoursInfo = `Opens ${
            tradingHours[indexForDay].dayOfWeek
          } ${hourFormatHelper(checkStoreOpenSameDay)}`;
        } else {
          isOpen = false;
          openHoursInfo = `Opens ${nextOpenDay.dayOfWeek} ${hourFormatHelper(
            nextOpeningTime
          )}`;
        }
        //check if store is opeing soon by adding 15 mins offset
        openingSoon = isStoreTimeValid(tradingHours, forDate, timeZone, 15);
      }
    } else {
      isOpen = false;
    }
  }

  //trading time info is only used in store details
  const openTime: StoreOpenTime = {
    isOpen: isOpen,
    tradingTimeInfo: openHoursInfo,
    closingSoon: closingSoon,
    openingSoon: openingSoon,
  };

  return openTime;
};

export const getMenuTimePeriodsForDay = (
  menuSection: MenuSection[],
  timezone: string,
  forDate?: Moment
): StoreMenu => {
  const timePeriodsForDay = findTimePeriodsForDay(
    menuSection,
    forDate?.toDate() ?? new Date()
  );

  const isSelectedTimeWithinTimePeriods = checkSelectedTimeWithinTradingHours(
    forDate?.toDate() ?? new Date(),
    timezone,
    menuSection
  );

  return {
    menuTimePeriods: timePeriodsForDay,
    isMenuOpen: isSelectedTimeWithinTimePeriods.isTimeValid,
    willMenuOpenForToday:
      isSelectedTimeWithinTimePeriods.fallBackTime?.isSame(new Date(), "day") ??
      true,
  };
};

/**
 * Finds store's object that matches the id.
 * @param storeId id of the store that must be found
 * @param stores array of stores
 * @returns store object
 */
export const getStoreById = (
  storeId: number,
  stores: Store[]
): Store | undefined => stores.find((store) => store.id === storeId);

/**
 * Converts 24 hour time to 12 hour format
 * @param time
 * @returns time in 12 hour format
 */
export const convert24To12Hour = (time: string): string => {
  const splitTime = time.split(":");
  const hour = Number(splitTime[0]);
  const minute = splitTime[1];

  const newHour = hour > 12 ? hour - 12 : hour === 0 ? 12 : hour;
  const isAMorPM = hour > 12 ? "pm" : "am";

  return `${newHour}:${minute + isAMorPM}`;
};

export const convertHourTo24format = (isAM: boolean, orderHour: number) => {
  return (isAM && orderHour !== 12) || (orderHour === 12 && !isAM)
    ? orderHour
    : orderHour + 12;
};

export const checkIfStoreHasTag = (
  tagToFind: StoreTagLabel,
  storeTags?: StoreTag[]
): boolean => {
  const storeTagsArray: string[] = storeTags
    ? Object.keys(storeTags).map((key, index) => storeTags[index].tag)
    : [];
  return storeTagsArray.includes(tagToFind) || false;
};

/**
 * Creates an address line for the store search result.
 * @param data store
 * @returns
 */
export const getAddress = (data: Store): string => {
  let address = "";

  const address1 = data.address1 ? data.address1.replace(".", "") : "";
  const address2 = data.address2 ? data.address2.replace(".", "") : "";
  const state = data.state ? data.state : "";

  address = `${address1 ? `${address1}, ` : ""}${
    address2 ? `${address2}, ` : ""
  }${state}`;

  // regex matches double or single commas with extra space
  address = address.replace(/,{2,}|,\s+,|\s+,{2,}|\s+,/g, ",");
  // removes comma if it's a last character
  address = address.replace(/,\s*$/, "");

  return address;
};

// return string array with Surcharges if they match time ranges within current order time
export const getSurchargesTextArray = (
  orderTime: number | null,
  surcharges: StoreSurcharges[]
): string[] => {
  const time = moment(orderTime ?? 0).unix();

  const surchargesTextArray = surcharges?.length
    ? surcharges
        .filter((surcharge) => {
          const startTime = surcharge.startDateTimeTs ?? 0;
          const endTime = surcharge.finishDateTimeTs ?? 0;
          return time >= startTime && endTime >= time;
        })
        .map((surcharge) => surcharge.surchargeDescription)
    : [];
  return surchargesTextArray;
};

export const getOrderNowTime = (): Date => {
  return moment().toDate();
};

/**
 * Returns the ASAP time based on the value offset
 * @param getOrderResponse OrderResponse with pickupTimeOffsets
 * @returns Date
 */
export const calculateTimeFromOffset = (
  getOrderResponse?: null | OrderResponse
): Date => {
  // if offset configuration is not available just return the time now
  if (!getOrderResponse || !getOrderResponse?.pickupTimeOffsets?.length) {
    return moment().toDate();
  }

  const firstPeriod = getOrderResponse.pickupTimeOffsets[0];
  const lastPeriod =
    getOrderResponse.pickupTimeOffsets.length > 1
      ? getOrderResponse.pickupTimeOffsets[1]
      : getOrderResponse.pickupTimeOffsets[0];

  const timeNow = moment();
  const firstPeriodStart = moment(firstPeriod.periodStart);
  const firstPeriodEnd = moment(firstPeriod.periodEnd);
  const lastPeriodStart = moment(lastPeriod.periodStart);
  const lastPeriodEnd = moment(lastPeriod.periodEnd);

  if (timeNow >= firstPeriodStart && timeNow <= firstPeriodEnd) {
    return moment().add(firstPeriod.offset, "minutes").toDate();
  } else if (timeNow >= lastPeriodStart && timeNow <= lastPeriodEnd) {
    return moment().add(lastPeriod.offset, "minutes").toDate();
  } else if (
    timeNow >= firstPeriodEnd &&
    timeNow <= lastPeriodStart &&
    lastPeriodStart != firstPeriodStart
  ) {
    //ASAP time is between pickup time ranges (store break), use next period start time
    return firstPeriodStart.add(lastPeriod.offset, "minutes").toDate();
  } else {
    return moment().toDate();
  }
};

export const isTimeMeetPickupTimeRanges = (
  timestamp: number,
  getOrderResponse: null | OrderResponse
): boolean => {
  const orderTime = moment(timestamp);

  if (!getOrderResponse || !getOrderResponse?.pickupTimeOffsets?.length) {
    return true;
  }
  const firstPeriod = getOrderResponse.pickupTimeOffsets[0];
  const lastPeriod =
    getOrderResponse.pickupTimeOffsets.length > 1
      ? getOrderResponse.pickupTimeOffsets[1]
      : getOrderResponse.pickupTimeOffsets[0];

  if (!firstPeriod.periodStart || !lastPeriod.periodEnd) {
    return true;
  }

  const firstPeriodStart = moment(firstPeriod.periodStart);
  const lastPeriodEnd = moment(lastPeriod.periodEnd);

  return orderTime >= firstPeriodStart && orderTime <= lastPeriodEnd;
};
