import moment from "moment-timezone";
import { Dispatch } from "redux";
import { DeliveryAddress } from "redux_store/order/models";

import { CheckoutAction } from "../../redux_store/checkout/checkout.slice";
import { orderActions } from "../../redux_store/order/order.slice";
import { OrderTimes, RateOrderPayload } from "../../services/api/order/model";
import { braintreeActions } from "../braintree/braintree.slice";
import { cartActions } from "../cart/cart.slice";

const isOrderTimeAvailable = (
  orderTime: number | null,
  availableTimes: OrderTimes[],
  timezone?: string
): boolean => {
  if (!orderTime) {
    return false;
  }
  const currentOrderDate = moment(orderTime).tz(timezone).format("YYYY-MM-DD");
  const currentOrderTime = moment(orderTime).tz(timezone).format("HH:mm");

  const isAvailableTimeObj = availableTimes.find(
    (availableTime: OrderTimes) => {
      if (availableTime.date === currentOrderDate) {
        const minimumTime = availableTime.timeSlots[0];
        const maximumTime =
          availableTime.timeSlots[availableTime.timeSlots.length - 1];

        //return true if currentOrderTime is in between the earliest and latest order time slot
        return (
          currentOrderTime >= minimumTime && currentOrderTime <= maximumTime
        );
      } else {
        return false;
      }
    }
  );
  return !!isAvailableTimeObj;
};

const cleanUpOrder = (dispatch: Dispatch, orderId: string) => {
  dispatch(cartActions.clearCart());
  dispatch(orderActions.clearOrderResponse());
  dispatch(orderActions.clearOrderStatus());
  dispatch(orderActions.setOrderResponse(null));
  dispatch(orderActions.updateCurrentOrderId(orderId));
  dispatch(braintreeActions.clearPaymentPayload());
  dispatch(orderActions.clearOrderSetup());
  dispatch(CheckoutAction.resetPayment());
  dispatch(CheckoutAction.clearClientToken());
};

const sendRating = (
  ratingValue: number,
  brazeId: string,
  orderId: string,
  dispatch: Dispatch
) => {
  const rateOrderPayload: RateOrderPayload = {
    rating: ratingValue,
    userFeedback: "",
    brazeId: brazeId,
    orderId: orderId,
  };
  dispatch(orderActions.rateOrder(rateOrderPayload));
};

const buildFullAddress = (
  address?: DeliveryAddress,
  {
    businessName,
    suiteAndFloor,
  }: {
    businessName?: string;
    suiteAndFloor?: string;
  } = {}
): string | undefined => {
  if (!address) {
    return;
  }

  const addressLine2 = [
    businessName ?? address.name,
    suiteAndFloor ?? address.suiteAndFloor,
  ]
    .filter((x) => x)
    .join(", ");

  const addressLine = [addressLine2, address.addressLine1]
    .filter((x) => x)
    .join("/");
  const suburbLine = [address.city, address.state, address.postCode]
    .filter((x) => x)
    .join(" ");
  return [addressLine, suburbLine].filter((x) => x).join(", ");
};

export { buildFullAddress, cleanUpOrder, isOrderTimeAvailable, sendRating };
