import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Store as ReduxStore } from "redux";

import {
  CartReduxAction,
  OrderModules,
  OrderReduxAction,
  OrderReduxModels,
  RootState,
  SentryLoggerInstance,
  StoreModules,
} from "../index";
import { HttpStatus } from "../services/httpClient";
import { getStatusCode } from "../utils/order";

export const UPDATE_ORDER_HOOK_ORDER_404 =
  "useCreateUpdateOrder: Order Response 404 - recreating order. Route:";
export const UPDATE_ORDER_HOOK_ORDER_GENERIC =
  "useCreateUpdateOrder: Order Response 4xx 5xx error - displaying modal. Route:";

const useCreateUpdateOrder = (
  store: ReduxStore,
  routeName: string,
  shouldMonitorBasketChangesAndUpdateOrder?: boolean
) => {
  const dispatch = useDispatch();
  const {
    orderASAP,
    orderCollectionType,
    delivery,
    orderTime,
    tableNumber,
    getOrderResponse,
    getOrderError,
    displayGenericOrderError,
  } = useSelector((s: RootState) => s.order);
  const { selectedStore, storeOrderTimes } = useSelector(
    (s: RootState) => s.store
  );
  const { posMenuId } = useSelector((state: RootState) => state.menu);
  const { items: cartItems, shouldUpdateOrder } = useSelector(
    (s: RootState) => s.cart
  );
  const { activeReward, redeemDollars } = useSelector(
    (state: RootState) => state.loyalty
  );
  const { loading: checkoutLoading } = useSelector(
    (s: RootState) => s.checkout
  );
  const [createOrderAfterMenuFetched, setCreateOrderAfterMenuFetched] =
    useState(false);

  const createOrder = useCallback(() => {
    if (selectedStore && !checkoutLoading && posMenuId) {
      dispatch(
        OrderReduxAction.setDisplayErrorModal(
          OrderReduxModels.ErrorModalState.NONE
        )
      );
      OrderModules.OrderUtils.createOrUpdateOrder(
        store,
        posMenuId,
        orderCollectionType,
        cartItems,
        orderTime,
        orderASAP,
        selectedStore,
        tableNumber,
        selectedStore.timeZoneInfo.storeTimeZone,
        null,
        false,
        activeReward,
        redeemDollars,
        delivery
      );
    }
  }, [
    dispatch,
    selectedStore,
    checkoutLoading,
    posMenuId,
    store,
    orderCollectionType,
    cartItems,
    orderTime,
    orderASAP,
    tableNumber,
    activeReward,
    redeemDollars,
    delivery,
  ]);

  const updateOrder = useCallback(
    (orderId?: string, triggeredByOrderTime?: boolean) => {
      if (selectedStore && !checkoutLoading && orderId) {
        //get next available time, triggeredByOrderTime called when time expired
        let finalTime = orderTime || 0;
        if (triggeredByOrderTime) {
          const calculatedAsapTime =
            StoreModules.StoreUtils.calculateTimeFromOffset(
              getOrderResponse
            ).valueOf();

          const time = new Date(
            OrderModules.OrderUtils.getNextAvailableTime(
              store,
              calculatedAsapTime,
              storeOrderTimes
            )
          );
          //check if order is ASAP or scheduled is earlier then next possible time
          if (orderASAP || time.valueOf() > orderTime) {
            finalTime = time.valueOf();
          }
        }

        dispatch(
          OrderReduxAction.setDisplayErrorModal(
            OrderReduxModels.ErrorModalState.NONE
          )
        );
        OrderModules.OrderUtils.createOrUpdateOrder(
          store,
          posMenuId,
          orderCollectionType,
          cartItems,
          finalTime,
          orderASAP,
          selectedStore,
          tableNumber,
          selectedStore.timeZoneInfo.storeTimeZone,
          orderId,
          !!triggeredByOrderTime,
          activeReward,
          redeemDollars,
          delivery
        );
      }
    },
    [
      dispatch,
      activeReward,
      cartItems,
      checkoutLoading,
      getOrderResponse,
      orderASAP,
      orderCollectionType,
      orderTime,
      posMenuId,
      redeemDollars,
      selectedStore,
      store,
      storeOrderTimes,
      tableNumber,
      delivery,
    ]
  );

  const createOrUpdateOrder = useCallback(() => {
    if (!getOrderResponse?.orderId && cartItems?.length > 0) {
      createOrder();
    } else {
      updateOrder(getOrderResponse?.orderId);
    }
    dispatch(CartReduxAction.setShouldUpdateOrder(false));
  }, [
    cartItems?.length,
    createOrder,
    dispatch,
    getOrderResponse?.orderId,
    updateOrder,
  ]);

  //create or update order when items are added to the cart
  useEffect(() => {
    if (
      shouldMonitorBasketChangesAndUpdateOrder === true &&
      shouldUpdateOrder
    ) {
      if (posMenuId) {
        createOrUpdateOrder();
      } else {
        dispatch(CartReduxAction.setShouldUpdateOrder(false));
        setCreateOrderAfterMenuFetched(true);
      }
    }
  }, [
    posMenuId,
    cartItems,
    createOrder,
    updateOrder,
    dispatch,
    getOrderResponse?.orderId,
    shouldMonitorBasketChangesAndUpdateOrder,
    shouldUpdateOrder,
    createOrUpdateOrder,
  ]);

  useEffect(() => {
    if (createOrderAfterMenuFetched && posMenuId) {
      createOrUpdateOrder();
      setCreateOrderAfterMenuFetched(false);
    }
  }, [createOrderAfterMenuFetched, posMenuId, createOrUpdateOrder]);

  //Create a new order when on 404 statusCode received
  useEffect(() => {
    if (
      getOrderError?.statusCode == HttpStatus.NotFound &&
      getOrderResponse &&
      shouldMonitorBasketChangesAndUpdateOrder === true
    ) {
      // 404 order patch can occur when checkout previously was
      // "BraintreeTransactionCreationFailedException" allow order recreate
      // or when the coupon is already claimed
      createOrder();
      SentryLoggerInstance.sentryCaptureCustomError(
        `${UPDATE_ORDER_HOOK_ORDER_404} ${routeName}`,
        getOrderError
      );
    }
  }, [
    createOrder,
    dispatch,
    getOrderError,
    getOrderResponse,
    routeName,
    shouldMonitorBasketChangesAndUpdateOrder,
  ]);

  // Handle any issues with order POST/PATCH scenarios
  // resulting in errors we don't foresee. Generic error handling
  useEffect(() => {
    const orderErrorStatusCode = getStatusCode(getOrderError?.statusCode);

    if (
      displayGenericOrderError === OrderReduxModels.ErrorModalState.NONE &&
      orderErrorStatusCode !== HttpStatus.NotFound &&
      orderErrorStatusCode !== HttpStatus.CheckoutInProgress &&
      (orderErrorStatusCode >= HttpStatus.BadRequest ||
        orderErrorStatusCode >= HttpStatus.InternalServerError)
    ) {
      dispatch(
        OrderReduxAction.setDisplayErrorModal(
          OrderReduxModels.ErrorModalState.ERROR
        )
      );
      SentryLoggerInstance.sentryCaptureCustomError(
        `${UPDATE_ORDER_HOOK_ORDER_GENERIC} ${routeName}`,
        getOrderError
      );
    }
  }, [dispatch, displayGenericOrderError, getOrderError, routeName]);

  return { createOrder, updateOrder };
};

export default useCreateUpdateOrder;
