import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Image,
  ImageSourcePropType,
  Platform,
  StyleSheet,
  View,
} from "react-native";
import { useSelector } from "react-redux";

import {
  AnalyticsConst,
  AnalyticsInstance,
  AnalyticsPayloadGenerator,
  OrderReduxModels,
  RootState,
  StoreModules,
} from "gyg_common";
import { IconButton, PrimaryBlackButton } from "gyg_common/components";
import editIcon from "gyg_common/components/assets/icons/icon_edit.png";
import pickupIcon from "gyg_common/components/assets/icons/services/icon_pickup.png";
import TertiaryButton from "gyg_common/components/Buttons/TertiaryButton";
import ErrorView from "gyg_common/components/Error/ErrorView";
import {
  AbandonOrderModal,
  enableAbandonOrderModal,
} from "gyg_common/components/OrderSetup/AbandonOrderModal";
import { SetupScreenComponent } from "gyg_common/components/OrderSetup/OrderSetupFlowContent";
import { Spacing } from "gyg_common/components/styles/number";
import { useMediaQuery } from "gyg_common/hooks/useMediaQuery";
import { StoreUtils } from "gyg_common/modules/Store";
import { ErrorResponse } from "gyg_common/redux_store/error/models";
import { buildErrorResponse } from "gyg_common/redux_store/error/utils";
import {
  CollectionType,
  PickupModalState,
  SetupOrderProps,
} from "gyg_common/redux_store/order/models";
import { getMenu } from "gyg_common/services/api/menu";
import * as ApiService from "gyg_common/services/api/store";

import { FlyingBurritoLoader } from "../shared/Loading/FlyingBurritoLoader/FlyingBurritoLoader";
import { OrderSetupSheetContent } from "./OrderSetupSheetContent";

import colours from "@/styles/colours";

const textStyles = (isDesktop: boolean) => {
  return StyleSheet.create({
    orderTypeText: {
      fontFamily: "Sini-Bold",
      fontSize: isDesktop ? 28 : 18,
      lineHeight: 1,
    },
  });
};

const styles = StyleSheet.create({
  container: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    padding: Spacing.Regular,
    paddingTop: 0,
  },
  contentContainer: {
    flex: 1,
    paddingBottom: Spacing.Light,
  },
  orderTypeContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  rowCentered: { flexDirection: "row", alignItems: "center" },
  iconContainer: {
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: colours.yellow,
    borderRadius: 100,
    width: 40,
    height: 40,
    marginRight: 10,
  },
  icon: { width: 30, height: 30 },
  infoContainer: {
    marginTop: Spacing.Regular,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  infoBoxContainer: { display: "flex", flexDirection: "column", rowGap: 10 },
  infoTitleText: {
    fontSize: 18,
    fontFamily: "Sini-Bold",
  },
  infoDescriptionText: {
    fontSize: 14,
    fontFamily: "Montserrat-Regular",
  },
  loadingContainer: {
    margin: Spacing.MediumThick,
    display: "flex",
    justifyContent: "center",
  },
});

const Screen: React.FC<SetupScreenComponent<PickupModalState>> = ({
  state,
  onChangeCollectionType,
  onSubmit,
  onBackToScreen,
}) => {
  const { t } = useTranslation();
  const { selectedStore, storeOpenTime, storeOrderOffset, storeOrderTimes } =
    useSelector((state: RootState) => state.store);

  const { totalPrice: cartTotalPrice, items } = useSelector(
    (state: RootState) => state.cart
  );
  const { menuStructure, menuOpenTime } = useSelector(
    (state: RootState) => state.menu
  );
  const { orderCollectionType, orderASAP, orderTime } = useSelector(
    (state: RootState) => state.order
  );
  const analyticsState = useSelector((s: RootState) => s.analytics);

  const [pickUpState, setPickUpState] = useState(state);
  const [isTableServiceSelected, setTableServiceSelected] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<ErrorResponse | null>(null);
  const [abandonOrderModalVisible, setAbandonOrderModalVisible] =
    useState(false);

  const { isDesktopScreen } = useMediaQuery();
  const isWebDesktop = Platform.OS === "web" && isDesktopScreen;

  useEffect(() => {
    const loadStoreData = async () => {
      if (state.ChosenStore) {
        setIsLoading(true);
        try {
          const timeSlots = await ApiService.getOrderTimeSlots(
            state.ChosenStore?.id,
            cartTotalPrice
          );

          let newMenuStructure = menuStructure;
          if (
            !newMenuStructure ||
            newMenuStructure?.store?.storeName !== state.ChosenStore.name
          ) {
            newMenuStructure = await getMenu(state.ChosenStore?.id);
          }
          const menuOpenTime = StoreUtils.getMenuTimePeriodsForDay(
            newMenuStructure.sections,
            newMenuStructure.store.timeZoneInfo.storeTimeZone
          );

          const storeOpenHours = StoreModules.StoreUtils.checkStoreOpenHours(
            state.ChosenStore?.tradingHours ?? [],
            state.ChosenStore.timeZoneInfo.storeTimeZone
          );

          setError(null);
          setPickUpState({
            ...pickUpState,
            PickupDetails: {
              ...pickUpState.PickupDetails,
              orderASAP: orderASAP,
              orderTime: orderTime,
              timeSlots,
              menuOpenTime,
              menuStructure: newMenuStructure,
              storeOpenHours,
              orderCollectionType,
            },
          });
          setIsLoading(false);
        } catch (error) {
          if (error) {
            const errorMessage = buildErrorResponse(error);
            setError(errorMessage);
          }
          setIsLoading(false);
        }
      }
    };
    loadStoreData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    cartTotalPrice,
    menuOpenTime,
    menuStructure,
    orderASAP,
    orderCollectionType,
    orderTime,
    selectedStore,
    selectedStore?.id,
    state,
    storeOpenTime,
    storeOrderOffset,
    storeOrderTimes,
  ]);

  const setOrderSetupFormData = (formData: SetupOrderProps) => {
    state.PickupDetails = formData;
    state.PickupDetails.menuStructure =
      pickUpState.PickupDetails?.menuStructure;
    state.PickupDetails.timeSlots = pickUpState.PickupDetails?.timeSlots;
    setTableServiceSelected(
      formData.orderCollectionType ===
        OrderReduxModels.CollectionType.TABLE_SERVICE
    );
  };

  const navigateToDownload = () => {};

  const onSwitchToDelivery = () => {
    //Check if basket is empty or not
    AnalyticsInstance.trackEvent(
      AnalyticsConst.Events.ChangeToDelivery,
      AnalyticsPayloadGenerator.storePayload(false, analyticsState)
    );
    if (items.length === 0 || !enableAbandonOrderModal) {
      onChangeCollectionType(CollectionType.DELIVERY);
    } else {
      setAbandonOrderModalVisible(true);
    }
  };

  const onAbandonModalClose = () => {
    setAbandonOrderModalVisible(false);
  };

  const addressText =
    state.ChosenStore?.address1?.length > 0
      ? state.ChosenStore?.address1
      : state.ChosenStore?.address2;

  return (
    <View style={styles.container}>
      <View style={styles.contentContainer}>
        <div style={styles.orderTypeContainer}>
          <View style={styles.rowCentered}>
            <View style={styles.iconContainer}>
              <Image
                source={pickupIcon as ImageSourcePropType}
                style={styles.icon}
                resizeMode='contain'
              />
            </View>
            <div style={textStyles(isWebDesktop).orderTypeText}>
              {t("OrderManagement:pickup").toUpperCase()}
            </div>
          </View>
          <div>
            <TertiaryButton
              onClick={onSwitchToDelivery}
              title={t("OrderManagement:changeToDelivery")}
              bgColor={colours.borderGrey}
            />
          </div>
        </div>
        <div className='grey-line divider' />
        <div style={styles.infoContainer}>
          <div style={styles.infoBoxContainer}>
            <div style={styles.infoTitleText}>
              {t("OrderManagement:gygRestaurant")}
            </div>
            <div style={styles.infoDescriptionText}>
              {state.ChosenStore?.name + `, ` + addressText}
            </div>
          </div>
          <div>
            <IconButton
              withShadow={false}
              onPress={() => {
                onBackToScreen("PickupRestaurantSelect");
              }}
              iconImage={editIcon}
            />
          </div>
        </div>

        <div className='grey-line divider' />

        {!isLoading &&
        pickUpState.PickupDetails?.storeOpenHours &&
        pickUpState.PickupDetails &&
        pickUpState.ChosenStore ? (
          <OrderSetupSheetContent
            cartTotalPrice={cartTotalPrice}
            storeOrderOffset={
              pickUpState.PickupDetails?.timeSlots?.orderOffset ?? []
            }
            storeOrderTimes={
              pickUpState.PickupDetails?.timeSlots?.orderTimes ?? []
            }
            menuLoading={isLoading}
            menuStructure={pickUpState.PickupDetails.menuStructure}
            storeOpenTime={pickUpState.PickupDetails.storeOpenHours!}
            menuOpenTime={pickUpState.PickupDetails.menuOpenTime!}
            selectedStore={pickUpState.ChosenStore}
            orderCollectionType={
              pickUpState.PickupDetails
                .orderCollectionType as OrderReduxModels.CollectionType
            }
            orderASAP={pickUpState.PickupDetails.orderASAP}
            orderTime={pickUpState.PickupDetails.orderTime}
            onChangeOrderCollectionType={() => {}}
            setData={setOrderSetupFormData}
            handleLink={navigateToDownload}
            withDelivery={true}
          />
        ) : (
          isLoading && (
            <div style={styles.loadingContainer}>
              <FlyingBurritoLoader />
            </div>
          )
        )}

        {error && (
          <ErrorView
            message={
              error?.message ??
              error?.heading ??
              t("OrderManagement:menuErrorMessage")
            }
          />
        )}
      </View>
      <PrimaryBlackButton
        loading={isLoading}
        disable={isTableServiceSelected}
        onClick={onSubmit.bind(this, state)}
        buttonName={t("OrderManagement:confirm")}
      />

      <AbandonOrderModal
        isModalVisible={abandonOrderModalVisible}
        isDelivery={false}
        abandonOrder={onChangeCollectionType.bind(
          this,
          CollectionType.DELIVERY
        )}
        keepCart={onAbandonModalClose}
        onModalClose={onAbandonModalClose}
      />
    </View>
  );
};

export default Screen;
