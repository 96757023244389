/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import config from "../../config";
import { isOlderThanActiveOrderThreshold } from "../../modules/Order/utils";
import {
  CheckoutResponse,
  RecentAndCurrentOrders,
} from "../../redux_store/checkout/model";
import {
  AddToAccountPayload,
  CreateOrderPayload,
  GetOrderByOrderIdPayload,
  GetOrderResponse,
  OrderResponse,
  RateOrderPayload,
  UpdateOrderPayload,
  UpdateOrderResponse,
} from "../../services/api/order/model";
import { ErrorResponse } from "../error/models";
import { AllActionsOf } from "../utils/Actions";
import {
  CollectionType,
  ErrorModalState,
  GetAuthenticatedOrdersPayload,
  OrderCollectionTypeProps,
  OrderState,
  RatedOrderProps,
  SetCurrentOrRecentOrders,
  SetupOrderProps,
} from "./models";

const initialState: OrderState = {
  orderCollectionType: CollectionType.PICK_UP,
  orderInitialTime: null,
  orderTime: null,
  orderASAP: true,
  email: "dan.mclean@gyg.com.au",
  tableNumber: null,
  includeCutlery: false,
  driveThruOptionAvailable: false,
  getOrderLoading: false,
  updateOrderLoading: false,
  getOrderError: null,
  displayGenericOrderError: ErrorModalState.NONE,
  currentOrderId: null,
  guestOrderId: null,
  getOrderResponse: null,
  userConfirmedOrderSetup: false,
  hasOrderPending: false,
  orderStatus: null,
  currentOrders: [],
  showMoreAvailable: true,
  recentOrders: [],
  tableInit: null,
  addToAccountLoading: false,
  addToAccountSuccess: false,
  getOrderByIdSuccess: false,
  generalError: null,
  getOrderByIdResponse: null,
  getOrderByIdError: false,
  orderTimeUpdated: false,
  getAuthenticatedOrdersLoading: false,
  ratingOrder: null,
  ratingValue: null,
  ignoreTimeChange: false,
  delivery: null,
  showOrderUpdatedModal: false,
};

const orderSlice = createSlice({
  name: "order",
  initialState: initialState,
  reducers: {
    setupOrderCollectionType(
      state,
      action: PayloadAction<OrderCollectionTypeProps>
    ) {
      state.orderCollectionType = action.payload.orderCollectionType;
    },
    setupOrder(state, action: PayloadAction<SetupOrderProps>) {
      state.orderCollectionType = action.payload.orderCollectionType;
      state.orderTime = action.payload.orderTime;
      state.orderInitialTime = action.payload.orderTime;
      state.ignoreTimeChange = false;
      state.orderASAP = action.payload.orderASAP;
      state.tableNumber = action.payload.tableNumber;
      state.tableInit = action.payload.tableInit;
      state.collectionStore = action.payload.collectionStore;
      state.delivery = action.payload.delivery;
      state.deliveryAddress = action.payload.deliveryAddress;
    },
    setOrderASAP(state, action: PayloadAction<boolean>) {
      state.orderASAP = action.payload;
    },
    updateOrderTime(state, action: PayloadAction<number | null>) {
      state.orderTime = action.payload;
      if (!state.orderInitialTime || state.orderTime < state.orderInitialTime) {
        state.ignoreTimeChange = false;
      }
    },
    updateOrderInitialTime(state, action: PayloadAction<number | null>) {
      state.orderInitialTime = action.payload;
    },
    setIgnoreTimeChange(state, action: PayloadAction<boolean>) {
      state.ignoreTimeChange = action.payload;
    },
    setOrderLoading(state, action: PayloadAction<boolean | null>) {
      state.getOrderLoading = action.payload;
    },
    confirmOrderSetup(state, action: PayloadAction<boolean>) {
      state.userConfirmedOrderSetup = action.payload;
    },
    clearOrderSetup(state) {
      state.orderInitialTime = null;
      state.orderTime = null;
      state.orderASAP = true;
      state.userConfirmedOrderSetup = false;
      state.tableNumber = undefined;
      state.tableInit = undefined;
      state.ignoreTimeChange = false;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    createOrder(state, action: PayloadAction<CreateOrderPayload>) {
      state.getOrderError = null;
      state.getOrderLoading = true;
    },
    getOrderError(state, action: PayloadAction<ErrorResponse>) {
      state.getOrderLoading = false;
      state.updateOrderLoading = false;
      state.getOrderError = action.payload;
    },
    setDisplayErrorModal(state, action: PayloadAction<ErrorModalState>) {
      state.displayGenericOrderError = action.payload;
    },
    updateCurrentOrderId(state, action: PayloadAction<string | null>) {
      state.currentOrderId = action.payload;
      state.guestOrderId = action.payload;
    },
    setIncludeCutlery(state, action: PayloadAction<boolean>) {
      state.includeCutlery = action.payload;
    },
    clearOrderResponse(state) {
      state.getOrderResponse = null;
      state.getOrderByIdResponse = null;
      state.currentOrderId = null;
    },
    setOrderResponse(state, action: PayloadAction<OrderResponse | null>) {
      if (action.payload) {
        state.currentOrderId = action.payload.orderId;
        state.guestOrderId = action.payload.orderId;
        if (action.payload.delivery) {
          state.delivery = action.payload.delivery;
        }
      } else {
        state.ignoreTimeChange = false;
        state.orderInitialTime = null;
      }

      state.getOrderLoading = false;
      state.getOrderError = null;
      state.getOrderResponse = action.payload;
    },
    setOrderPending(state, action: PayloadAction<boolean>) {
      state.hasOrderPending = action.payload;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getOrderStatus(state, action: PayloadAction<GetOrderByOrderIdPayload>) {
      state.getOrderLoading = true;
    },
    getOrderStatusSuccess(state, action: PayloadAction<GetOrderResponse>) {
      state.getOrderLoading = false;
      state.orderStatus = action.payload;

      // update order status if it was changed
      const index = state.currentOrders.findIndex(
        (obj) => obj.order.orderId == action.payload.orderId
      );
      if (
        index !== -1 &&
        state.currentOrders[index].order.orderFinalisationStatus !==
          action.payload.orderFinalisationStatus
      ) {
        const order = state.currentOrders[index].order;
        order.orderFinalisationStatus = action.payload.orderFinalisationStatus;
        order.orderProgressStatus = action.payload.orderProgressStatus;
      }
    },
    getAuthenticatedOrders(
      state,
      // used in saga
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<GetAuthenticatedOrdersPayload | undefined>
    ) {
      state.getAuthenticatedOrdersLoading = true;
      state.getOrderError = null;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    addToAccount(state, action: PayloadAction<AddToAccountPayload>) {
      state.addToAccountLoading = true;
      state.addToAccountSuccess = false;
    },
    addToAccountError(state) {
      state.addToAccountLoading = false;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setToAccount(state, action: PayloadAction<AddToAccountPayload>) {
      state.addToAccountLoading = false;
      state.addToAccountSuccess = true;
    },
    setCurrentOrRecentOrders(
      state,
      action: PayloadAction<SetCurrentOrRecentOrders>
    ) {
      state.getAuthenticatedOrdersLoading = false;
      if (action.payload.currentOrRecentOrders.length < config.orderListLimit) {
        state.showMoreAvailable = false;
      }

      // Add a const variable to avoid unwanted useEffect watch side-effects
      const orders: RecentAndCurrentOrders = {
        recentOrders: state.recentOrders,
        currentOrders: state.currentOrders,
      };

      if (action.payload.clearCurrentAndRecentOrders) {
        orders.recentOrders = [];
        orders.currentOrders = [];
      }

      action.payload.currentOrRecentOrders.forEach((payload) => {
        if (isOlderThanActiveOrderThreshold(payload)) {
          orders.recentOrders.push(payload);
        } else {
          orders.currentOrders.push(payload);
        }
      });

      state.recentOrders = orders.recentOrders;
      state.currentOrders = orders.currentOrders;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getOrderById(state, action: PayloadAction<string>) {
      state.getOrderLoading = true;
      state.getOrderByIdSuccess = false;
      state.getOrderByIdError = false;
    },
    getOrderByIdSuccess(state, action: PayloadAction<CheckoutResponse>) {
      state.getOrderLoading = false;
      state.getOrderByIdSuccess = true;
      state.getOrderByIdResponse = action.payload;
      state.getOrderByIdError = false;
    },
    getOrderByIdError(state) {
      state.getOrderLoading = false;
      state.getOrderByIdError = true;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    updateOrderById(state, action: PayloadAction<UpdateOrderPayload>) {
      state.orderTimeUpdated = false;
      state.updateOrderLoading = true;
    },
    updateOrderByIdSuccess(state, action: PayloadAction<UpdateOrderResponse>) {
      state.getOrderResponse = action.payload.orderResponse;
      state.updateOrderLoading = false;
      state.orderTimeUpdated = !!action.payload.orderTimeUpdated;
      state.getOrderError = null;
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    rateOrder(_state, _: PayloadAction<RateOrderPayload>) {},

    clearOrderStatus(state) {
      state.orderStatus = null;
    },
    clearOrderError(state) {
      state.getOrderError = null;
      state.orderTimeUpdated = false;
    },

    updateRatedOrderAll(state, action: PayloadAction<RatedOrderProps | null>) {
      state.ratingOrder = action.payload;
    },

    updateRatingValue(state, action: PayloadAction<number | null>) {
      if (action.payload) {
        state.ratingValue = action.payload;
      }
    },

    setShowOrderUpdatedModal(state, action: PayloadAction<boolean | null>) {
      state.showOrderUpdatedModal = action.payload;
    },
  },
});

const { actions, reducer } = orderSlice;
export type OrderActionsType = AllActionsOf<typeof actions>;
export { initialState, actions as orderActions, reducer as orderReducer };
