import React, { useState } from "react";
import { Platform, Pressable, StyleSheet, Text, View } from "react-native";

import * as PlatformUtils from "../../modules/platformUtils";
import colours from "../styles/colours";
import { BorderRadius, Spacing } from "../styles/number";
import { shadowTextstyle } from "../styles/shared";
import { Typography } from "../styles/typography";
import Spinner from "./Spinner";

const styles = (props: SecondaryButtonProps) =>
  StyleSheet.create({
    container: {
      width: "100%",
      flexDirection: "row",
      borderRadius: BorderRadius.Medium,
      borderWidth: 2,
      borderColor: props.disable ? colours.lightGrey : colours.yellow,
      backgroundColor: props.buttonColor,
      paddingVertical: Spacing.Thin,
      marginHorizontal: Spacing.Narrow,
    },
    loadingContainer: {
      width: "100%",
      flexDirection: "row",
      backgroundColor: props.buttonColor,
      paddingVertical: Spacing.Thin,
      marginHorizontal: Spacing.Narrow,
      justifyContent: "center",
      alignItems: "center",
    },
    buttonContainer: {
      flex: 1,
      flexDirection: "row",
      paddingRight: Spacing.Light,
      paddingLeft: Spacing.Light,
      justifyContent: "center",
      alignItems: "center",
    },
    leftView: {
      flex: 1,
      justifyContent: "flex-start",
    },
    centerText: {
      ...Typography.titleThree,
      flex: props.rightIcon || props.icon ? null : 2,
      justifyContent: "center",
      textAlign: "center",
      color: props.disable ? colours.uiGrey : colours.black,
      paddingTop: Platform.OS == "ios" ? Spacing.Narrow : 0,
    },
    rightText: {
      ...Typography.btnPrice,
      flex: 1,
      justifyContent: "flex-end",
      color: props.disable ? colours.uiGrey : colours.black,
      textAlign: "right",
    },
  });
interface ButtonColorProp {
  buttonColor?: string;
}
export interface SecondaryButtonProps extends ButtonColorProp {
  buttonName: string;
  loading?: boolean;
  disable?: boolean;
  testId?: string;
  price?: number;
  rightIcon?: JSX.Element;
  icon?: JSX.Element;
  onClick: () => void;
}

export default function SecondaryButton(props: SecondaryButtonProps) {
  const [buttonColor, setButtonColor] = useState(
    props.buttonColor ?? colours.white
  );

  const styleProps = {
    ...props,
    buttonColor: buttonColor,
  };

  return (
    <Pressable
      {...PlatformUtils.generateTestID(
        Platform.OS,
        props.testId ?? "SecondaryButton"
      )}
      disabled={props.disable}
      onPressIn={() => setButtonColor(colours.starburst)}
      onPressOut={() => setButtonColor(colours.white)}
      onPress={props.onClick}
      style={styles(styleProps).container}>
      {!!props.loading ? (
        <View style={styles(styleProps).buttonContainer}>
          <View style={[styles(styleProps).loadingContainer]}>
            <Spinner color={colours.switchGrey} />
          </View>
        </View>
      ) : (
        <View style={styles(styleProps).buttonContainer}>
          {!!props.price && !props.icon && (
            <View style={styles(props).leftView} />
          )}
          {!!props.icon && props.icon}
          <Text
            style={[styles(styleProps).centerText, shadowTextstyle(1).shadow]}>
            {props.buttonName}
          </Text>
          {!!props.price && !props.rightIcon && (
            <Text style={styles(styleProps).rightText}>
              ${props.price.toFixed(2)}
            </Text>
          )}
          {!!props.rightIcon && props.rightIcon}
        </View>
      )}
    </Pressable>
  );
}
