import { removeQueriesFromUrl } from "./UrlParse";

interface ErrorLog {
  code?: number;
  message?: string;
  url?: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getErrorLog(error: any): ErrorLog {
  if (error.response) {
    try {
      return {
        code:
          error.response?.status ??
          error.response?.statusCode ??
          error.response?.code,
        message: JSON.stringify(error.response.data),
      };
    } catch (err) {
      console.error("Error parsing error log");
    }
  } else if (
    typeof error === "object" &&
    error !== null &&
    typeof error.status === "number"
  ) {
    const responseObject = error as Response;
    return {
      code:
        responseObject.status ||
        error?.response?.status ||
        error.response?.code ||
        error?.response?.statusCode ||
        0,
      url: removeQueriesFromUrl(responseObject.url),
      message: error?.response?.data?.message ?? error?.message,
    };
  } else if (
    typeof error === "object" &&
    error !== null &&
    typeof error.message === "string" &&
    typeof error.code === "string"
  ) {
    return {
      code: error.code,
      message: error.message,
    };
  } else if (
    typeof error === "object" &&
    error !== null &&
    typeof error.message === "string"
  ) {
    return {
      message: error.message,
    };
  } else if (typeof error === "object" && error !== null) {
    return {
      message: JSON.stringify(error),
    };
  } else {
    return {
      message: String(error),
    };
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getErrorForLogging(error: any): string {
  return JSON.stringify(getErrorLog(error));
}
