import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Keyboard,
  KeyboardEvent,
  Modal,
  NativeModules,
  Platform,
  ScrollView,
  StatusBar,
  StyleSheet,
  View,
} from "react-native";

import useLockedBody from "../../../hooks/useLockedBody";
import { generateTestID } from "../../../modules/platformUtils";
import colours from "../../styles/colours";
import { BorderRadius, Spacing } from "../../styles/number";

export enum WebModalSize {
  AUTO = "auto",
  SMALL = "small",
  LARGE = "large",
}

// adjust container margin on keyboard display, taking into consideration status bar height
const containerMarginTop = (keyboardHeight: number) => {
  return keyboardHeight > 0
    ? (StatusBar.currentHeight ?? NativeModules.StatusBarManager.HEIGHT ?? 0) +
        Spacing.Light
    : 0;
};
const containerMarginBottom = (keyboardHeight: number) => {
  return keyboardHeight > 0 ? keyboardHeight + Spacing.Light : 0;
};

const styles = (keyboardHeight: number, backgroundColor: string) =>
  StyleSheet.create({
    overlay: {
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: colours.blackOpacity,
    },
    container: {
      width: "90%",
      backgroundColor: backgroundColor,
      borderRadius: BorderRadius.Medium,
      marginTop: containerMarginTop(keyboardHeight),
      marginBottom: containerMarginBottom(keyboardHeight),
    },
  });

const stylesWeb = (backgroundColor: string, maxHeight?: string) =>
  StyleSheet.create({
    container: {
      maxWidth: "auto",
      position: "absolute",
      maxHeight: maxHeight ? maxHeight : "100vh",
      backgroundColor: backgroundColor,
      borderRadius: BorderRadius.Medium,
    },
    containerSmall: {
      maxWidth: "374px",
      position: "absolute",
      maxHeight: maxHeight ? maxHeight : "100vh",
      backgroundColor: backgroundColor,
      borderRadius: BorderRadius.Medium,
    },
    containerLarge: {
      maxWidth: "568px",
      position: "absolute",
      maxHeight: maxHeight ? maxHeight : "100vh",
      backgroundColor: backgroundColor,
      borderRadius: BorderRadius.Medium,
    },
  });

export interface MiddleModalProps {
  scrollViewContainer?: boolean;
  testId?: string;
  children: ReactNode;
  isVisible: boolean;
  webModalSize?: WebModalSize;
  transparent?: boolean;
  backgroundColor?: string;
  maxHeight?: string;
  scrollToBottomOnKeyboardOpen?: boolean;
  onDismiss?: () => void;
  onAndroidBackPress?: () => void;
}

const MiddleModal: React.FC<MiddleModalProps> = ({
  scrollViewContainer = true,
  testId,
  children,
  isVisible,
  backgroundColor = colours.white,
  webModalSize,
  maxHeight,
  scrollToBottomOnKeyboardOpen = false,
  onDismiss,
  onAndroidBackPress,
}) => {
  const scrollViewRef = useRef<ScrollView>();
  const [keyboardHeight, setKeyboardHeight] = useState<number>(0);

  const onKeyboardDidShow = (e: KeyboardEvent): void => {
    if (Platform.OS !== "web") setKeyboardHeight(e.endCoordinates.height);
  };

  const onKeyboardDidHide = (): void => {
    if (Platform.OS !== "web") setKeyboardHeight(0);
  };

  const [, setLocked] = useLockedBody(false, "root");

  useEffect(() => {
    setLocked(isVisible);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  useEffect(() => {
    const keyboardDidShowListener = Keyboard.addListener(
      "keyboardDidShow",
      onKeyboardDidShow
    );
    const keyboardDidHideListener = Keyboard.addListener(
      "keyboardDidHide",
      onKeyboardDidHide
    );
    return (): void => {
      keyboardDidShowListener.remove();
      keyboardDidHideListener.remove();
    };
  }, []);

  const modalStyle = useMemo(() => {
    if (Platform.OS === "web") {
      if (webModalSize == WebModalSize.SMALL) {
        return stylesWeb(backgroundColor, maxHeight).containerSmall;
      } else if (webModalSize == WebModalSize.LARGE) {
        return stylesWeb(backgroundColor, maxHeight).containerLarge;
      } else {
        return stylesWeb(backgroundColor, maxHeight).container;
      }
    } else {
      return styles(keyboardHeight, backgroundColor).container;
    }
  }, [backgroundColor, keyboardHeight, maxHeight, webModalSize]);

  const id = () => {
    if (Platform.OS === "web") {
      return "middle-modal-" + testId;
    } else {
      return testId ?? "MiddleModal";
    }
  };

  const handleScrollLayoutChanges = useCallback(() => {
    if (scrollToBottomOnKeyboardOpen) {
      scrollViewRef.current?.scrollToEnd({ animated: false });
    }
  }, [scrollToBottomOnKeyboardOpen]);

  return (
    <Modal
      {...generateTestID(Platform.OS, id())}
      visible={isVisible}
      onRequestClose={onAndroidBackPress}
      onDismiss={onDismiss}
      animationType={Platform.OS == "web" ? null : "fade"}
      transparent
      statusBarTranslucent={true}
      presentationStyle='overFullScreen'>
      <View style={styles(keyboardHeight, backgroundColor).overlay}>
        <View style={modalStyle}>
          {scrollViewContainer ? (
            <ScrollView
              ref={scrollViewRef}
              onLayout={handleScrollLayoutChanges}>
              {children}
            </ScrollView>
          ) : (
            children
          )}
        </View>
      </View>
    </Modal>
  );
};

export default MiddleModal;
