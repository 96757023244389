import { useFeatureIsOn } from "@growthbook/growthbook-react";
import branch, { BranchError, DeepLinkData } from "branch-sdk";
import { useMediaQuery } from "hooks/useMediaQuery";
import React, { useEffect, useRef } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { store } from "redux_store/configureReduxStore";

import config from "config";
import {
  AnalyticsConst,
  AnalyticsInstance,
  BranchConst,
  CartReduxAction,
  DashboardReduxAction,
  MenuActions,
  OrderModules,
  OrderReduxAction,
  OrderReduxModels,
  RootState,
  StoreModules,
  StoreReduxAction,
} from "gyg_common";
import OrderErrorModal from "gyg_common/components/modals/OrderErrorModal";
import useCreateUpdateOrder from "gyg_common/hooks/useCreateUpdateOrder";
import { FeatureFlags } from "gyg_common/modules/FeatureFlags/constants";
import {
  CollectionType,
  DeliveryModalState,
  getMenuChannelId,
  PickupModalState,
} from "gyg_common/redux_store/order/models";
import { Screens } from "navigation/const";

import { OrderSetup } from "@/views/components/OrderSetup/NotWithDelivery/OrderSetup";
import { OrderSetup as OrderSetupWithDelivery } from "@/views/components/OrderSetup/OrderSetup";

export interface OrderSetupContainerProps {
  isVisible: boolean;
  onCloseModal: () => void;
  onConfirmClick: () => void;
}

export const OrderSetupContainer: React.FC<OrderSetupContainerProps> = ({
  isVisible,
  onCloseModal,
  onConfirmClick,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = useLocation();
  const { isDesktopScreen } = useMediaQuery();
  const { selectedStore, storeOpenTime, storeOrderOffset, storeOrderTimes } =
    useSelector((state: RootState) => state.store);
  const { totalPrice: cartTotalPrice } = useSelector(
    (state: RootState) => state.cart
  );
  const {
    menuStructure,
    loading: menuLoading,
    error: menuError,
    menuOpenTime,
    posMenuId,
  } = useSelector((state: RootState) => state.menu);
  const { orderCollectionType, orderASAP, orderTime, getOrderResponse } =
    useSelector((state: RootState) => state.order);

  const { recentOrderToAddToCart } = useSelector(
    (state: RootState) => state.dashboard
  );

  const { isBrazeInitialised } = useSelector((s: RootState) => s.user);

  const shouldCreateOrUpdateOrder = useRef(false);
  const { createOrder, updateOrder } = useCreateUpdateOrder(
    store,
    pathname,
    false
  );

  const hasDelivery = useFeatureIsOn(FeatureFlags.IN_APP_DELIVERY as string);

  /** Reset the user's order setup confirmation when they click change restaruants.
   * Then navigate them to the restaurant's list.
   */
  const handleChangeRestaurant = () => {
    onCloseModal();
    history.push(Screens.Restaurants, {
      previousScreen: pathname,
    });
  };

  /**
   * Set the user's order setup
   * Set the user's confirmation and load the menu for the selected store
   * Then navigate to the appropriate screen
   */
  const handleOrderConfirmation = (
    orderSetup: OrderReduxModels.SetupOrderProps
  ) => {
    let selectedTime = orderSetup.orderTime;
    if (
      selectedTime &&
      OrderModules.OrderUtils.isOrderTimeAsapExpired(selectedTime, true)
    ) {
      const calculatedAsapTime =
        StoreModules.StoreUtils.calculateTimeFromOffset(
          getOrderResponse
        ).valueOf();

      const time: Date = new Date(
        OrderModules.OrderUtils.getNextAvailableTime(
          store,
          calculatedAsapTime,
          storeOrderTimes,
          selectedStore?.timeZoneInfo.storeTimeZone,
          getOrderResponse
        )
      );
      selectedTime = time.valueOf();
    }

    dispatch(OrderReduxAction.clearOrderResponse());
    dispatch(OrderReduxAction.setOrderResponse(null));

    shouldCreateOrUpdateOrder.current = true;
    dispatch(
      OrderReduxAction.setupOrder({
        ...orderSetup,
        orderTime: selectedTime,
      })
    );
    dispatch(OrderReduxAction.confirmOrderSetup(true));

    if (recentOrderToAddToCart) {
      const cartItems = OrderModules.OrderUtils.basketMapper(
        recentOrderToAddToCart,
        menuStructure
      );
      dispatch(CartReduxAction.addItemToCart({ cartItems, source: pathname }));

      dispatch(DashboardReduxAction.setRecentOrderToAddToCart(null));

      AnalyticsInstance.trackEvent(AnalyticsConst.Events.AddToOrder, {
        source: AnalyticsConst.AddToOrderSource.RECENTORDER,
      });
    }
    dispatch(CartReduxAction.setShouldUpdateOrder(true));
    onConfirmClick();
  };

  const handleNavigateToStoreDetails = () => {
    onCloseModal();
    dispatch(StoreReduxAction.setStoreInfo(selectedStore));
    history.push(Screens.Restaurants, {
      previousScreen: pathname,
    });
  };

  const handleOrderChange = (
    orderSetup: OrderReduxModels.OrderCollectionTypeProps
  ) => {
    dispatch(
      OrderReduxAction.setupOrderCollectionType({
        orderCollectionType: orderSetup.orderCollectionType,
      })
    );
  };

  const handleDownloadLink = (platform: string) => {
    AnalyticsInstance.trackEvent(
      AnalyticsConst.Events.TableServiceOrderSetup,
      {}
    );

    if (isDesktopScreen) {
      window.open(
        platform === "ios"
          ? `${config.appStoreLink}`
          : `${config.googlePlayLink}`,
        "_blank"
      );
    } else {
      const linkData: DeepLinkData = {
        data: {
          path: BranchConst.BranchPaths.OrderSetup,
          pickUpType: "Table service",
        },
      };
      try {
        branch.link(linkData, function (err: BranchError, link: string | null) {
          if (link) {
            window.open(link, "_blank");
          }
        });
      } catch (err) {
        console.error(err);
      }
    }
  };

  const onCloseIconClick = () => {
    onCloseModal();
  };

  useEffect(() => {
    if (orderTime && shouldCreateOrUpdateOrder.current && posMenuId) {
      shouldCreateOrUpdateOrder.current = false;
      if (getOrderResponse?.orderId) {
        updateOrder(getOrderResponse?.orderId);
      } else {
        createOrder();
      }
    }
  }, [
    createOrder,
    getOrderResponse?.orderId,
    orderTime,
    updateOrder,
    posMenuId,
  ]);

  /**
   * Analytics view
   */
  useEffect(() => {
    if (isVisible && isBrazeInitialised) {
      AnalyticsInstance.trackView({
        page_name: "order_setup",
        page_type: "order_setup_view",
      });
    }
  }, [isVisible, isBrazeInitialised]);

  useEffect(() => {
    if (selectedStore) {
      dispatch(
        StoreReduxAction.setStoreOpenTime(
          StoreModules.StoreUtils.checkStoreOpenHours(
            selectedStore?.tradingHours ?? [],
            selectedStore.timeZoneInfo.storeTimeZone
          )
        )
      );
    }
  }, [dispatch, selectedStore]);

  const selectStoreAndFetchDataForChosenStore = (state: PickupModalState) => {
    //Reset menu structure if order type changes
    if (
      orderCollectionType !== state.OrderCollectionType &&
      orderCollectionType
    ) {
      if (cartTotalPrice > 0) {
        dispatch(OrderReduxAction.setShowOrderUpdatedModal(true));
      }
      dispatch(MenuActions.resetMenuStructure());
    }
    batch(() => {
      if (state.ChosenStore) {
        dispatch(StoreReduxAction.setStore(state.ChosenStore));
        dispatch(
          StoreReduxAction.getStoreOrderTimeslotsAndOffset({
            storeId: state.ChosenStore.id,
            cartTotalAmount: 0,
          })
        );
      }
    });

    dispatch(
      MenuActions.getMenuStructure({
        channelId: getMenuChannelId(state.OrderCollectionType),
        storeId: state.ChosenStore.id,
      })
    );
  };

  const handleOnSubmitDeliveryFromModal = (state: DeliveryModalState) => {
    selectStoreAndFetchDataForChosenStore(state);
    handleOrderConfirmation({
      orderCollectionType: CollectionType.DELIVERY,
      orderASAP: true,
      orderTime: null,
      delivery: {
        pickupAddress: state.PickupAddress,
        dropoffAddress: state.DropoffAddress,
        dropoffSuiteAndFloor: state.Apartment,
        dropoffBusinessName: state.Business,
        dropoffOption: state.DropOffOption!,
        dropoffPinCodeRequired: state.DropoffPinCodeRequired,
        dropoffNotes: state.Instructions,
        deliveryDuration: state.DeliveryDuration,
      },
      deliveryAddress: state.DropoffAddress,
      collectionStore: state.ChosenStore,
    });
  };

  const handleOnSubmitPickupFromModal = (state: PickupModalState) => {
    selectStoreAndFetchDataForChosenStore(state);
    if (state.PickupDetails) {
      handleOrderConfirmation({
        ...state.PickupDetails,
        collectionStore: selectedStore,
        orderCollectionType: CollectionType.PICK_UP,
      });
    }
  };

  return (
    <>
      <OrderErrorModal />
      {!hasDelivery && (
        <OrderSetup
          cartTotalPrice={cartTotalPrice}
          storeOrderOffset={storeOrderOffset}
          menuError={menuError}
          menuLoading={menuLoading}
          menuStructure={menuStructure}
          storeOpenTime={storeOpenTime}
          storeOrderTimes={storeOrderTimes}
          menuOpenTime={menuOpenTime}
          isVisible={isVisible}
          selectedStore={selectedStore}
          orderCollectionType={orderCollectionType}
          orderASAP={orderASAP}
          orderTime={orderTime}
          onOrderConfirmClick={handleOrderConfirmation}
          onChangeRestaurantClick={handleChangeRestaurant}
          onChangeOrderCollectionType={handleOrderChange}
          navigateBack={() => history.goBack()}
          navigateToStoreDetails={handleNavigateToStoreDetails}
          onCloseModal={onCloseIconClick}
          navigateToDownload={handleDownloadLink}
        />
      )}
      {hasDelivery && isVisible && (
        <OrderSetupWithDelivery
          isVisible={isVisible}
          store={selectedStore}
          onCloseModal={onCloseIconClick}
          onSubmitDelivery={handleOnSubmitDeliveryFromModal}
          onSubmitPickup={handleOnSubmitPickupFromModal}
        />
      )}
    </>
  );
};
