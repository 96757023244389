import { ProductModuleModel } from "index";
import React, { useCallback, useMemo, useState } from "react";
import { FlatList, LayoutAnimation, Platform, UIManager } from "react-native";

import { generateTestID } from "../../modules/platformUtils";
import * as ProductConst from "../../modules/Products/const";
import {
  OptionDataItemProps,
  OptionDataProps,
} from "../../modules/Products/model";
import GroupFooter from "./GroupFooter";
import GroupItem from "./GroupItem";

if (
  Platform.OS === "android" &&
  UIManager.setLayoutAnimationEnabledExperimental
) {
  UIManager.setLayoutAnimationEnabledExperimental(true);
}

export interface CheckboxButtonGroupProps {
  renderItem: (el: OptionDataItemProps) => React.ReactElement;
  onItemSelection: (id: (string | number)[]) => void;
  handleToggleShowAll?: () => void;
  data: OptionDataProps[];
  selectedId: (string | number)[];
  showGradientLine?: boolean;
  maximumSelection?: number;
  footerTitle?: string;
  isRemoveSection?: boolean;
  customisationState?: ProductModuleModel.CustomizableOptionState;
}

export interface RenderCheckboxButtonGroupItemProps {
  item: OptionDataProps;
  index: number;
}

const keyExtractor = (item) => `${item.element.id}`;

const CheckboxButtonGroup: React.FC<CheckboxButtonGroupProps> = ({
  data,
  footerTitle,
  renderItem,
  showGradientLine,
  onItemSelection,
  handleToggleShowAll,
  isRemoveSection,
  selectedId,
  maximumSelection,
  customisationState,
}) => {
  const [showMore, setShowMore] = useState<boolean>(false);
  const checkboxOptionsLength = data.length;

  const validateSelection = (id: number | string, selected: boolean) => {
    let result: (string | number)[] = [...selectedId];
    if (selected) {
      //unSelect the button
      result = result.filter((n) => n != id);
    } else {
      //Select the button
      result.push(id);
    }
    onItemSelection(result);
  };

  const handleShowAll = useCallback(() => {
    if (handleToggleShowAll) {
      handleToggleShowAll();
    }

    if (Platform.OS !== "web") {
      LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
    }

    setShowMore((prevValue) => !prevValue);
  }, [handleToggleShowAll]);

  const renderCheckboxButtonGroupItem = ({
    item,
    index,
  }: RenderCheckboxButtonGroupItemProps) => {
    const selected =
      selectedId.findIndex((n: string | number) => n === item.element.id) != -1;
    let disable = item.disabled;

    if (!!customisationState) {
      disable = customisationState[
        isRemoveSection
          ? ProductConst.SectionTitle.ExtraModifier
          : ProductConst.SectionTitle.RemoveModifier
      ]?.includes(item.element.id)
        ? true
        : disable;
    }

    if (
      maximumSelection &&
      selectedId.length >= maximumSelection &&
      selected == false
    ) {
      disable = true;
    }

    return (
      <GroupItem
        key={item.element.id}
        element={item.element}
        validateSelection={validateSelection}
        renderItem={renderItem}
        disabled={disable}
        selected={selected}
        isRemoveSection={isRemoveSection}
        showGradientLine={showGradientLine && index !== data.length - 1}
      />
    );
  };

  const renderListFooterComponent = useMemo(() => {
    return checkboxOptionsLength > 3 ? (
      <GroupFooter
        showMore={showMore}
        handleShowAll={handleShowAll}
        footerTitle={footerTitle}
        dataLength={checkboxOptionsLength}
      />
    ) : null;
  }, [checkboxOptionsLength, footerTitle, handleShowAll, showMore]);

  return (
    <FlatList
      {...generateTestID(Platform.OS, `${footerTitle}FlatList`)}
      data={showMore ? data : data.slice(0, 3)}
      keyExtractor={keyExtractor}
      renderItem={renderCheckboxButtonGroupItem}
      ListFooterComponent={renderListFooterComponent}
    />
  );
};

export default CheckboxButtonGroup;
