import axios, { AxiosError, AxiosResponse, Method } from "axios";
import { Platform, PlatformOSType } from "react-native";

import DeviceInfo from "gyg_common/utils/deviceInfo";

import config from "../config";
import { AxiosErrorRedactor, HttpErrorResponse } from "./axiosErrorRedact";

export const timeoutCode = "ECONNABORTED";
export const canceledCode = "canceled";
const timeoutText = "timeout";

export enum HttpErrorTitle {
  ProductItemsNotFoundException = "ProductItemsNotFoundException",
}

export enum HttpStatus {
  Ok = 200,
  Created = 201,
  Accepted = 202,
  NoContent = 204,
  BadRequest = 400,
  Unauthorized = 401,
  Forbidden = 403,
  Conflict = 409,
  NotFound = 404,
  CheckoutInProgress = 421,
  InternalServerError = 500,
}
export interface Header {
  [key: string]: string;
}

export interface QueryParams {
  [key: string]: string | boolean;
}

export interface RequestBody {
  [key: string]: unknown;
}

export interface URLSearchParams {
  [key: string]: unknown;
}

export interface HttpClientParams {
  method: Method;
  url: string;
  headers?: Header;
  queryParams?: QueryParams;
  data?: RequestBody;
  abortController?: AbortController;
  useFQDN?: boolean;
}

export interface VersionAndPlatformData {
  clientPlatformType?: PlatformOSType;
  clientVersion?: string;
}

interface HttpClientError extends HttpErrorResponse {
  code?: string | number;
  status?: string | number;
  url?: string;
  isTimeout: boolean;
}

const redactor = new AxiosErrorRedactor(
  false, //redactRequestData
  false, //redactResponseData
  false //redactQueryData
);

const errorInterceptor = (
  error: AxiosError | null | undefined
): HttpClientError | HttpErrorResponse | Error | unknown => {
  const redactedError: HttpErrorResponse | Error = redactor.redactError(error);
  //check if there is error response to build HttpClientError otherwise return default error
  if ((redactedError as HttpErrorResponse)?.response) {
    const httpError = redactedError as HttpErrorResponse;
    const timeOut = redactedError?.message?.includes(timeoutText);
    const errorResponse: HttpClientError = {
      ...httpError,
      code: timeOut ? timeoutCode : httpError.response.statusCode,
      status: timeOut ? timeoutCode : httpError.response.statusCode,
      url: httpError.fullURL,
      isTimeout: timeOut,
    };

    /**
     * Do not try to refresh the access token when the failed request was a public endpoint.
     * This is an edge and just a technical possibility. Normally public endpoint should never
     * return 401. error?.config?.headers?.Authorization != undefined
     */
    if (
      errorResponse.status == HttpStatus.Unauthorized &&
      error?.config?.headers?.Authorization != undefined
    ) {
      //need to call this to alow createAuthRefreshInterceptor to work as expected
      return Promise.reject(error);
    }
    return Promise.reject(errorResponse);
  } else {
    return Promise.reject(redactedError);
  }
};

axios.interceptors.response.use(undefined, errorInterceptor);

export const httpClient = async (
  params: HttpClientParams
): Promise<AxiosResponse<unknown>> => {
  const { method, url, headers, queryParams, data, abortController, useFQDN } =
    params;
  try {
    const userAgent = await DeviceInfo.getUserAgent();

    const defaultHeaders = {
      "x-gyg-session-id": config.sessionId,
      "x-gyg-platform-type": `${Platform.OS} ${Platform.Version || ""}`,
      "x-gyg-app-version": config.appVersion,
      "X-Amz-User-Agent": userAgent,
    };

    const response = await axios({
      url: useFQDN ? `${config.api.fqdn}/${url}` : `${config.api.host}/${url}`,
      method: method,
      headers: headers
        ? {
            ...headers,
            ...defaultHeaders,
          }
        : {
            ...defaultHeaders,
            "Content-Type": "application/json",
          },
      params: queryParams,
      data: data || undefined,
      timeout: config.api.timeout,
      signal: abortController ? abortController.signal : null,
    });

    return response;
  } catch (e) {
    throw e;
  }
};

export const getIP = async (): Promise<AxiosResponse<string>> => {
  try {
    const res = await axios.get("https://geolocation-db.com/json/");
    return res.data.IPv4;
  } catch (e) {
    throw e;
  }
};
